<i18n>
{
  "zh-CN": {
    "": ""
  },
  "en-US": {
    "": ""
  }
}
</i18n>

<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <caption>{{ $t('tablasNutricionales.valoresDiarios') }}</caption>
    <thead class="text-uppercase text-center">
      <tr>
        <th scope="col"></th>
        <th scope="col">{{ $t('tablasNutricionales.cada100G') }}</th>
        <th scope="col">% VD (*)</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>380 Kcal = 1590 Kj</td>
        <td>19</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.carbohidratos') }}</td>
        <td>93 g</td>
        <td>31</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>0 g</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>0 g</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_saturadas') }}</td>
        <td>0 g</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>0 g</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>0 g</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>38,5 g</td>
        <td>1.6</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TablePolicarb",
};
</script>
