<template>
  <div id="poliCarb">
    <Header
      imgUrl="header-modulosnutricionales.png"
      :classes="{ texto: 'align-middle', img: 'w-100' }"
      :texto="$t('productos.header_mn')"
    />

    <div class="container my-4">
      <div class="row">
        <div class="col-md-4 col-xs-12 pe-0">
          <img
            src="@/assets/images/productos/Lata_PoliCarb.webp"
            class="img-fluid pb-2"
          />
          <div class="d-flex flex-column">
            <Sabor color="#e6e7e8" :texto="$t('productos.saborNeutro')" />

            <span class="mt-2">
              {{ $t("productos.presentacionLata", { gr: 400 }) }}
            </span>
          </div>
        </div>
        <div class="col-md-8 col-xs-12 ps-0 pl-mobile">
          <div class="row">
            <p class="pt-3" v-html="$t('productos.poliCarb.descripcion')"></p>
          </div>

          <div class="row">
            <CaracteristicasNutricionales
              :columnas="1"
              :caracteristicas="caracteristicas"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.poliCarb.perfilCarbohidratos.titulo") }}</h5>
      </div>
    </div>
    <div class="container">
      <table
        style="width: 40%"
        class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5"
      >
        <tbody class="text-center">
          <tr>
            <td>{{ $t("productos.poliCarb.perfilCarbohidratos.dextrosa") }}</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>{{ $t("productos.poliCarb.perfilCarbohidratos.maltosa") }}</td>
            <td>3%</td>
          </tr>
          <tr>
            <td>
              {{ $t("productos.poliCarb.perfilCarbohidratos.maltotriosa") }}
            </td>
            <td>8%</td>
          </tr>
          <tr>
            <td>
              {{ $t("productos.poliCarb.perfilCarbohidratos.superiores") }}
            </td>
            <td>87%</td>
          </tr>
        </tbody>
        <caption>
          {{
            $t("productos.poliCarb.perfilCarbohidratos.caption")
          }}
        </caption>
      </table>
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("tablasNutricionales.titulo") }}</h5>
        <button
          class="btn btn-sm btn-azul"
          @click="toggleTablaNutricional = !toggleTablaNutricional"
        >
          {{ toggleTablaNutricional ? "+" : "-" }}
        </button>
      </div>
    </div>

    <div class="container" :class="{ oculto: toggleTablaNutricional }">
      <TablePoliCarb />
    </div>

    <div class="container-fluid ntr-collapse py-3 my-2">
      <div class="container d-flex justify-content-between">
        <h5>{{ $t("productos.modoPreparacion.titulo") }}</h5>
      </div>
    </div>

    <div class="container">
      <ModoPreparacion2
        :texto="$t('productos.modoPreparacion.texto')"
        :tabla="this.$i18n.locale == 'en' ? tablaEn : tablaEs"
        :medida="
          $t('productos.modoPreparacion.medida', {
            gr: '5,7',
            producto: 'Poli-carb®',
          })
        "
      />
    </div>

    <div class="container-fluid ntr-collapse bg-azul py-3 my-2">
      <div class="container">
        <h5>{{ $t("productos.otrosProductos") }}</h5>
      </div>
    </div>

    <CardsRecomendados
      :productos="[
        'nutricion-clinica-pediatricos',
        'nutricion-clinica-adultos',
        'modulos-nutricionales',
        'dieta-cetogenica',
      ]"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sabor from "@/components/elementos/Sabor.vue";
import CaracteristicasNutricionales from "@/components/CaracteristicasNutricionales.vue";
import es from "@/locales/es.json";
import en from "@/locales/en.json";
import TablePoliCarb from "@/components/tablas/TablePoliCarb.vue";
import ModoPreparacion2 from "@/components/ModoPreparacion2.vue";
import CardsRecomendados from "@/components/CardsRecomendados.vue";

export default {
  name: "PoliCarb",
  components: {
    Header,
    Sabor,
    CaracteristicasNutricionales,
    TablePoliCarb,
    ModoPreparacion2,
    CardsRecomendados,
  },
  data() {
    return {
      toggleTablaNutricional: true,
      caracteristicas: {
        en: en.productos.poliCarb.caracteristicas,
        es: es.productos.poliCarb.caracteristicas,
      },
      tablaEs: es.productos.poliCarb.tablaPreparacion,
      tablaEn: en.productos.poliCarb.tablaPreparacion,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>